import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { dsConfig } from '@design-system/cdk/config';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsTableModule } from '@design-system/components/table';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  ChartData,
  SlSharedReportingBarChartComponent,
  SlSharedReportingSingleValueWidgetComponent,
} from '@sales-libs/shared/ui';
import {
  BestInvestFeature,
  BestInvestFeaturesInput,
} from '@sales-libs/upselling/data-access';
import { SlDetailsDialogComponent } from '@sales-libs/upselling/ui';
import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { take } from 'rxjs';
import { SlUpsellingBestInvestFeaturesTableComponent } from '../best-invest-features-table/best-invest-features-table.component';
import { SlUpsellingActions } from '../store';

@Component({
  selector: 'sl-upselling-best-invest-savings',
  templateUrl: './best-invest-savings.component.html',
  styleUrls: ['./best-invest-savings.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatRadioModule,
    MatIconModule,
    NgxChartsModule,
    DsSpacingModule,
    DsLoadingModule,
    SlSharedReportingBarChartComponent,
    SlSharedReportingSingleValueWidgetComponent,
    SlUpsellingBestInvestFeaturesTableComponent,
    DsTableModule,
    DsPlaceholderModule,
  ],
  providers: [CurrencyPipe],
})
export class SlUpsellingBestInvestSavingsComponent implements OnInit {
  @Input()
  projectId: number;
  @Input()
  cartId: number;
  @Input()
  currencyCode: string;
  @Input()
  featuresInput: BestInvestFeaturesInput;

  bestInvestFeaturesAvailable: boolean;
  softFactsData: BestInvestFeature[];
  monetaryBenefitsData: BestInvestFeature[];
  monetaryBenefitsChartData: ChartData[];

  totalOfMonetaryBenefits: string;
  numberOfSoftFacts: number;

  singleValueColors: string[] = [
    dsConfig.colors.primary[600],
    dsConfig.colors.primary[400],
  ];

  constructor(
    public readonly _store: Store,
    public readonly _currencyPipe: CurrencyPipe,
    public readonly actions: Actions,
    public readonly dialog: MatDialog,
    readonly _ga4Service: GoogleAnalytics4Service,
  ) {}

  ngOnInit() {
    this.getBestInvestFeatures();
  }

  getBestInvestFeatures(): void {
    if (
      !this.projectId ||
      !this.cartId ||
      !this.featuresInput.feature_filtering?.product
    ) {
      this.bestInvestFeaturesAvailable = false;
      return;
    }

    this._store.dispatch(
      SlUpsellingActions.getBestInvestFeatures({
        projectId: this.projectId,
        cartId: this.cartId,
        bestInvestFeaturesInput: this.featuresInput,
      }),
    );

    this.actions
      .pipe(ofType(SlUpsellingActions.getBestInvestFeaturesSuccess), take(1))
      .subscribe((data) => {
        if (
          (!data.payload.monetary_benefits ||
            data.payload.monetary_benefits.length === 0) &&
          (!data.payload.soft_facts || data.payload.soft_facts.length === 0)
        ) {
          this.bestInvestFeaturesAvailable = false;
          return;
        }

        this.monetaryBenefitsData = data.payload.monetary_benefits ?? [];
        this.softFactsData = data.payload.soft_facts ?? [];
        this._mapChartData();
        this._setReportingData();

        this.bestInvestFeaturesAvailable = true;
      });

    this.actions
      .pipe(ofType(SlUpsellingActions.getBestInvestFeaturesError), take(1))
      .subscribe(() => {
        this.bestInvestFeaturesAvailable = false;
      });
  }

  openMoreDetailsDialog(dataId: string): void {
    const feature = this.monetaryBenefitsData.find(
      (data) => data.id === dataId,
    );

    if (feature && (feature.description || feature.assumptions)) {
      this._ga4Service.event('OpenMoreDetailsDialogBarChartClick', {
        category: 'BestInvestSavings',
        label: 'BestInvest',
      });

      this.dialog.open(SlDetailsDialogComponent, {
        width: String(dsConfig.spacing * 25) + 'px',
        data: {
          feature: feature,
        },
      });
    }
  }

  private _setReportingData() {
    const sumOfMonetaryBenefits = this.monetaryBenefitsData
      .map((item) => item.value ?? 0)
      .reduce((prev, curr) => prev + curr, 0)
      .toFixed(2);

    this.totalOfMonetaryBenefits =
      this._currencyPipe.transform(
        sumOfMonetaryBenefits,
        this.currencyCode,
        'symbol',
      ) ?? '';

    this.numberOfSoftFacts = this.softFactsData.length;
  }

  private _mapChartData() {
    this.monetaryBenefitsChartData = this._mapItems(
      this.monetaryBenefitsData ?? [],
    );
  }

  private _mapItems(items: BestInvestFeature[]): ChartData[] {
    const initialAcc: ChartData[] = [];

    items.forEach((item) =>
      initialAcc.push({
        id: item.id,
        name: item.name ?? '',
        value: item.value ?? 0,
      }),
    );

    return initialAcc;
  }
}
