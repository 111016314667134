import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Currency } from '@sales-libs/settings/data-access';
import { SlSettingsCurrencyDependendPageComponent } from '@sales-libs/settings/feature';
import { UNITS } from '@sales-libs/shared/util';
import { BestInvestDealerParameters } from '@sales-libs/upselling/data-access';
import { take } from 'rxjs';
import { SlUpsellingActions } from '../store';

@Component({
  selector: 'sl-upselling-best-invest-global-settings',
  templateUrl: './global-settings.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatIconModule,
    SlSettingsCurrencyDependendPageComponent,
  ],
})
export class SlUpsellingBestInvestGlobalSettingsComponent implements OnInit {
  globalSettingsForm: FormGroup;
  dealerParameters: BestInvestDealerParameters;
  Units = UNITS;
  currency: Currency;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly store: Store,
    public actions: Actions,
    private readonly destroyRef: DestroyRef,
    private readonly snackbar: DsSnackbarService,
    private readonly translateService: TranslateService,
  ) {
    this.globalSettingsForm = this._formBuilder.group({
      costs_external_working_hour: ['', [Validators.required]],
      price_diesel: ['', [Validators.required]],
      price_hydraulic_oil: ['', [Validators.required]],
      price_lubricant: ['', [Validators.required]],
      price_co2_per_ton: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getOrCreateDefaultBestInvestDealerParameters();
  }

  getOrCreateDefaultBestInvestDealerParameters() {
    this.store.dispatch(SlUpsellingActions.getBestInvestDealerParameters());

    this.actions
      .pipe(
        ofType(SlUpsellingActions.getBestInvestDealerParametersSuccess),
        take(1),
      )
      .subscribe((event) => {
        this.dealerParameters = event.dealerParameters;
        this._setBestInvestDealerSettingsValues();
      });

    this.actions
      .pipe(
        ofType(
          SlUpsellingActions.createDefaultBestInvestDealerParametersSuccess,
        ),
        take(1),
      )
      .subscribe((event) => {
        this.dealerParameters = event.dealerParameters;
        this._setBestInvestDealerSettingsValues();
      });
  }

  loadCurrency(currency: Currency): void {
    this.currency = currency;
  }

  onSubmit(): void {
    if (this.globalSettingsForm.valid) {
      const updatedData = this.globalSettingsForm.getRawValue();

      this.store.dispatch(
        SlUpsellingActions.updateBestInvestDealerParameters({
          bestInvestDealerParametersInput: updatedData,
        }),
      );

      this.actions
        .pipe(
          ofType(SlUpsellingActions.updateBestInvestDealerParametersSuccess),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.snackbar.queue(
            this.translateService.instant(
              'settings.best_invest.global_settings_updated',
            ),
            {
              type: DsSnackbarType.Success,
            },
          );
        });

      this.actions
        .pipe(
          ofType(SlUpsellingActions.updateBestInvestDealerParametersError),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.snackbar.queue(
            this.translateService.instant(
              'error_messages.best_invest.global_settings_not_updated',
            ),
            {
              type: DsSnackbarType.Error,
            },
          );
        });
    }
  }

  private _setBestInvestDealerSettingsValues() {
    this.globalSettingsForm.patchValue({
      costs_external_working_hour:
        this.dealerParameters.costs_external_working_hour,
      price_diesel: this.dealerParameters.price_diesel,
      price_hydraulic_oil: this.dealerParameters.price_hydraulic_oil,
      price_lubricant: this.dealerParameters.price_lubricant,
      price_co2_per_ton: this.dealerParameters.price_co2_per_ton,
    });
  }
}
