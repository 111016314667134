import { createAction, props } from '@ngrx/store';
import {
  BestInvestFeatures,
  BestInvestFeaturesInput,
  BestInvestDealerParameters,
  BestInvestDealerParametersInput,
  BestInvestProjectParameters,
  BestInvestProjectParametersInput,
  ProblemDetails,
} from '@sales-libs/upselling/data-access';

export namespace SlUpsellingActions {
  export const getBestInvestProjectParameters = createAction(
    '[Upselling] GET_BEST_INVEST_PROJECT_PARAMETERS',
    props<{ projectId: number }>(),
  );
  export const getBestInvestProjectParametersSuccess = createAction(
    '[Upselling] GET_BEST_INVEST_PROJECT_PARAMETERS_SUCCESS',
    props<{ parameters: BestInvestProjectParameters }>(),
  );
  export const getBestInvestProjectParametersError = createAction(
    '[Upselling] GET_BEST_INVEST_PROJECT_PARAMETERS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const updateBestInvestProjectParameters = createAction(
    '[Upselling] UPDATE_BEST_INVEST_PROJECT_PARAMETERS',
    props<{
      projectId: number;
      bestInvestProjectParametersInput: BestInvestProjectParametersInput;
    }>(),
  );
  export const updateBestInvestProjectParametersSuccess = createAction(
    '[Upselling] UPDATE_BEST_INVEST_PROJECT_PARAMETERS_SUCCESS',
    props<{ parameters: BestInvestProjectParameters }>(),
  );
  export const updateBestInvestProjectParametersError = createAction(
    '[Upselling] UPDATE_BEST_INVEST_PROJECT_PARAMETERS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  // BEST INVEST - FEATURES
  export const getBestInvestFeatures = createAction(
    '[Cart] GET_BEST_INVEST_FEATURES',
    props<{
      projectId: number;
      cartId: number;
      bestInvestFeaturesInput: BestInvestFeaturesInput;
    }>(),
  );
  export const getBestInvestFeaturesSuccess = createAction(
    '[Cart] GET_BEST_INVEST_FEATURES_SUCCESS',
    props<{ payload: BestInvestFeatures }>(),
  );
  export const getBestInvestFeaturesError = createAction(
    '[Cart] GET_BEST_INVEST_FEATURES_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const createDefaultBestInvestDealerParameters = createAction(
    '[Upselling] CREATE_DEFAULT_BEST_INVEST_DEALER_PARAMETERS',
  );
  export const createDefaultBestInvestDealerParametersSuccess = createAction(
    '[Upselling] CREATE_DEFAULT_BEST_INVEST_DEALER_PARAMETERS_SUCCESS',
    props<{ dealerParameters: BestInvestDealerParameters }>(),
  );
  export const createDefaultBestInvestDealerParametersError = createAction(
    '[Upselling] CREATE_DEFAULT_BEST_INVEST_DEALER_PARAMETERS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getBestInvestDealerParameters = createAction(
    '[Upselling] GET_BEST_INVEST_DEALER_PARAMETERS',
  );
  export const getBestInvestDealerParametersSuccess = createAction(
    '[Upselling] GET_BEST_INVEST_DEALER_PARAMETERS_SUCCESS',
    props<{ dealerParameters: BestInvestDealerParameters }>(),
  );
  export const getBestInvestDealerParametersError = createAction(
    '[Upselling] GET_BEST_INVEST_DEALER_PARAMETERS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const updateBestInvestDealerParameters = createAction(
    '[Upselling] UPDATE_BEST_INVEST_DEALER_PARAMETERS',
    props<{
      bestInvestDealerParametersInput: BestInvestDealerParametersInput;
    }>(),
  );
  export const updateBestInvestDealerParametersSuccess = createAction(
    '[Upselling] UPDATE_BEST_INVEST_DEALER_PARAMETERS_SUCCESS',
    props<{ dealerParameters: BestInvestDealerParameters }>(),
  );
  export const updateBestInvestDealerParametersError = createAction(
    '[Upselling] UPDATE_BEST_INVEST_DEALER_PARAMETERS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const setProjectParametersToDealerDefaults = createAction(
    '[Upselling] SET_PROJECT_PARAMETERS_TO_DEALER_DEFAULTS',
    props<{ projectId: number }>(),
  );
  export const setProjectParametersToDealerDefaultsSuccess = createAction(
    '[Upselling] SET_PROJECT_PARAMETERS_TO_DEALER_DEFAULTS_SUCCESS',
    props<{ parameters: BestInvestProjectParameters }>(),
  );
  export const setProjectParametersToDealerDefaultsError = createAction(
    '[Upselling] SET_PROJECT_PARAMETERS_TO_DEALER_DEFAULTS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );
}
