<form
  [formGroup]="globalSettingsForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  gaLabelGroup="BestInvestGlobalSettings"
  gaCategoryGroup="GlobalSettings"
  class="global-settings-form"
  data-test="global-settings-form"
>
  <sl-settings-currency-dependend-page (currencyLoad)="loadCurrency($event)">
    <h1
      fxLayout="row wrap"
      fxLayoutAlign="space-between baseline"
      data-test="title"
    >
      {{ 'settings.best_invest.page_name' | translate }}
      <button
        mat-button
        page-action
        type="submit"
        [disabled]="!globalSettingsForm.valid"
        gaClickListener="SaveGlobalSettingsClick"
        data-test="save-button"
      >
        <mat-icon>save</mat-icon>{{ 'general.save' | translate }}
      </button>
    </h1>

    <div>
      <h2>{{ 'benefits.extended_data_input.costs_and_prices' | translate }}</h2>
      <p>* {{ 'sales_shared.common.mandatory' | translate }}</p>
      <mat-card class="full-width">
        <mat-card-content>
          <mat-form-field
            class="full-width"
            data-test="external-working-hour-field"
            gaClickListener="ExternalWorkingHourCostClick"
          >
            <mat-label>
              {{
                'benefits.extended_data_input.external_working_hour' | translate
              }}</mat-label
            >
            <input
              matInput
              type="number"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              formControlName="costs_external_working_hour"
              required
              autocomplete="off"
              data-test="external-working-hour"
            />
            <span matTextSuffix> {{ currency?.symbol }}/{{ Units.Hour }}</span>
            <mat-error
              *ngIf="
                globalSettingsForm.controls.costs_external_working_hour?.invalid
              "
            >
              <span>{{ 'sales_shared.common.required' | translate }}</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="full-width" gaClickListener="DieselPriceClick">
            <mat-label>{{
              'benefits.extended_data_input.price_for_diesel' | translate
            }}</mat-label>
            <input
              matInput
              required
              type="number"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              formControlName="price_diesel"
              autocomplete="off"
            />
            <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Liter }}</span>
            <mat-error
              *ngIf="globalSettingsForm.controls.price_diesel?.invalid"
            >
              <span>{{ 'sales_shared.common.required' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="full-width"
            gaClickListener="HydraulicOilPriceClick"
          >
            <mat-label>{{
              'benefits.extended_data_input.price_for_hydraulic_oil' | translate
            }}</mat-label>
            <input
              matInput
              required
              type="number"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              formControlName="price_hydraulic_oil"
              autocomplete="off"
            />
            <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Liter }}</span>
            <mat-error
              *ngIf="globalSettingsForm.controls.price_hydraulic_oil?.invalid"
            >
              <span>{{ 'sales_shared.common.required' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="full-width"
            gaClickListener="LubricantPriceClick"
          >
            <mat-label>{{
              'benefits.extended_data_input.price_for_lubricant' | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              required
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              formControlName="price_lubricant"
              autocomplete="off"
            />
            <span matTextSuffix>{{ currency?.symbol }}/{{ Units.Kg }}</span>
            <mat-error
              *ngIf="globalSettingsForm.controls.price_lubricant?.invalid"
            >
              <span>{{ 'sales_shared.common.required' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" gaClickListener="CO2PriceClick">
            <mat-label>{{
              'benefits.extended_data_input.price_per_ton_of_co2' | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              required
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              formControlName="price_co2_per_ton"
              autocomplete="off"
            />
            <span matTextSuffix
              >{{ currency?.symbol }}/{{ Units.CO2Ton }}
            </span>
            <mat-error
              *ngIf="globalSettingsForm.controls.price_co2_per_ton?.invalid"
            >
              <span>{{ 'sales_shared.common.required' | translate }}</span>
            </mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </sl-settings-currency-dependend-page>
</form>
